.widgetGold {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 15px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 130px;
  border-style: solid;  
  border-width: 1px;  
  border-color: #fff;
  background-color: #000;

  .spinner {
    width: 24px;
    height: 24px;
    border: 2px solid;
    border-color: #fff transparent #fff transparent;
    border-radius: 50%;
    animation: spin-anim 1.1s linear infinite;
    margin-left: 45%;
    align-self: center;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: 800;
      font-size: 23px;
      color: #fff;
    }

    .goldweight {
      font-weight: 600;
      font-size: 25px;
      color: #fff;
    }

    .goldamount {
      font-weight: 500;
      width: max-content;
      font-size: 19px;
      color: #fff;
      // border-bottom: 1px solid gray;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;

      &.positive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }

    .icon {
      font-size: 18px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-end;
    }
  }
}
