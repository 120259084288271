.categoryListsContainer{
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    flex-wrap: wrap;
}
.categoryLists{
    background-color: silver;
    margin-right: 7px;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 5px;
    font-size: 13px;
}