.podcastDescriptionMainContainer{
  display: flex;
}
.podcastDescriptionContainer {
  flex: 6;
}
.podcastListItem{
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.podcastImageContainer{
  margin-right: 20px;
}
.imagePodcast{
  width: 160px;
  height: 160px;
  border-radius: 10%;
  object-fit: cover;
  margin-bottom: 3px;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  /* align-self: center; */
}
.totalEpisodeText{
  text-align: center;
  margin-bottom: 3px;
}
.podcastListItemInfo{
  flex:1;
  justify-content: center;
  /* align-self: center; */
}
.podcastName{
  font-size: 17px;
  color: white;
  font-weight: 700;
}
.podcastAuthor{
  font-size: 15px;
  color: white;
  margin-top: 6px;
}
.podcastDescriptionText{
  font-size: 13px;
  color: white;
  margin-right: 30px;
  margin-top: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.imageSecondary{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  align-self: center;
}
.podcastListItemGoldInfo{
  flex:0.6;
  justify-content: center;
  /* align-self: center; */
}
.usernameText{
  font-size: 17px;
  color: #2364E7;
}
.sentYouText{
  font-size: 13px;
  color: #757479;
}
.goldWeightText{
  font-size: 15px;
  color: #1EB548;
  text-align: right;
}
.goldRateText{
  font-size: 13px;
  text-align: right;
  color: white;
}
.boostRecieved{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  color: white;
}
.boostRecievedText{
  font-size: 12px;
  align-self: center;
  color: white;
}
.boostRecievedDuration{
  display: flex;
  flex-direction: row;
  background-color: white;
}
.boostRecievedDurationText{
  margin-right: 16px;
  font-size: 16px;
}
.boostRecievedDurationLast{
  color: white;
  font-size: 16px;
}
.allBox{
  display: flex;
  flex-direction: row;
}
.box{
  padding: 10px;
  margin-left: 10px;
}
.box.active{
  border: 1px solid black;
  background: black;
  color: white;
}
.goldValueInDuration{
  font-size: 18px;
  margin-top: 5px;
}
.searchListItem{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 20px;
}
.allepisodesText{
  font-size: 23px;
  align-self: center;
  text-decoration: underline;
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid lightgray;
  padding: 10px;
  border-radius: 20px;
  margin-left: 20px;
  width: 400px;
}
.inputFieldEpisode {
  border: none;
  outline: none;
  background: transparent;
  width: 400px;
}
.epsiodeDataContainer{
  background-color: #A453F5;
  padding: 20px;
  margin-left: 15px;
  margin-right: 15px;
}
.episodeNameText{
  color: white;
  font-size: 18px;
  font-weight: 800;
}
.episodePublishDate{
  color: white;
  font-size: 11px;
  margin-bottom: 5px;
}
.episodeDescriptionText{
  color: white;
  font-size: 12px;
  margin-right: 20px;
}
.tabsContainer{
  background-color: black;
  border-radius: 15px;
  margin-top: 8px;
  color: white;
}
.episodeListItem{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.episodeListItemInfo{
  /* flex:0.2;  */
  justify-content: center;
}
.episodeTime{
  font-size: 11px;
  color: black;
  margin-top: 6px;
}
.buttonPlay{
  height: 60px;
  width: 60px;
  background-color: black;
  border-radius: 50%;
  margin-left: 20%;
  display: flex;
  justify-content: center;
}
.imagePlay{
  width: 30px;
  height: 30px;
  align-self: center;
}
.videoModal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 10px;
}
.closeText{
  text-align: center;
  margin-top: 4px;
  font-size: 18px;
}
.audioContainer{
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: center;
}
.audioPlayer{
  margin-bottom: 10px;
  flex-direction: column;
  background-color: black;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
}
.podcastAudioText{
  font-size: 16px;
  color: white;
  margin-bottom: 5px;
}
.episodeAudioText{
  font-size: 13px;
  color: white;
  margin-bottom: 10px;
}
.audioPlayerNormal{
  display: flex;
  width: 300px;
  flex-direction: column;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  margin-top: 5px;
}
.playerContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.iconClose{
  margin-left: 3px;
}
.podcastCategoryListContainer{
  display: flex;
  flex-direction: row;
  margin-right: 40px;
  flex-wrap: wrap;
}
.podcastCategoryList{
  background-color: silver;
  margin-right: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  margin-top: 5px;
}
.paginationBarContainer{
  justify-content: center;
}
.backArrow{
  margin-top: 10px;
  margin-left: 10px;
}
