.datatable {
  height: 600px;
  padding: 20px;

  .errorMsg{
    font-size: 16px;
    color: red;
  }

  .datagrid{
    color: #fff;
  }
  
  .datatableTitle{
    width: 100%;
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link{
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: deepskyblue;
      border: 1px dotted deepskyblue;
      cursor: pointer;
    }
    
    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}

.boxStyle{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 20px;

  .errorMsg{
    font-size: 16px;
    color: red;
    text-align: center;
  }

  .addTitle{
    text-align: center;
    margin-bottom: 15px;
    text-decoration: underline;
    font-size: 20px;
  }
  
  form {

    .formInput {
      width: 100%;

      label {
        font-weight: bold;

        .icon {
          cursor: pointer;
        }
      }

      input {
        width: 96%;
        padding: 10px;
        border: 1px solid gray;
        margin-top: 6px;
        font-size: 16px;
        margin-bottom: 10px;
      }
      
      select {
        width: 100%;
        padding: 10px;
        border: 1px solid gray;
        margin-top: 6px;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .buttonsContainer{
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
      font-size: 20px;

      button {
        width: 20%;
        padding: 3px;
        border: none;
        background-color: rgb(1, 179, 255);
        color: white;
        font-weight: bold;
        cursor: pointer;
        font-size: 20px;
      }
      
      .closeText {
        margin-left: 20px;
      }
    }
  }
}