.keywordListContainer{
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    flex-wrap: wrap;
}
.brandMentionsList{
    display: flex;
    flex-direction: row;
    background-color: silver;
    margin-right: 10px;
    border-radius: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.noKeywordList{
    font-size: 12px;
    color: gray;
}
.addBrandNameContainer{
    display: flex;
    justify-content: flex-end;
}
.addBrandNameButton{
    text-decoration: none;
    color: green;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid green;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    margin-bottom: 20px;
}
.insertBrandButton{
    margin-left: 5px;
}
.counts{
    background-color: black;
    margin-left: 7px;
    border-radius: 50%;
    color: white;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
}
.deleteText{
    color: white;
    background-color: red;
    margin-left: 5px;
    margin-right: 5px;
    width: 20%;
    padding: 1px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
    text-align: center;
}