.speakerListContainer{
    display: flex;
    flex-direction: column;
}
.speaker{
    font-size: 16px;
    font-weight: bold;
    color: white;
}
.speakerText{
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 15px;
    color: white;
}
.addSpeakerNameContainer{
    display: flex;
    justify-content: flex-end;
}
.addSpeakerNameButton{
    text-decoration: none;
    color: green;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid green;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
}