  .boostedListItem{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    background-color: #000;
    padding: 10px;
    border-radius: 10px;
    border-width: 2px;
    border-color: black;
    border-style: solid;
  }
  .imageMain {
    width: 50px;
    height: 50px;
    border-radius: 10%;
    margin-right: 20px;
    object-fit: cover;
    align-self: center;
  }
  .boostedListItemInfo{
    flex:1;
    justify-content: center;
    align-self: center;
  }
  .podcastName{
    font-size: 17px;
    color: white;
  }
  .podcastAuthor{
    font-size: 13px;
    color: white;
  }
  .episodeNumber{
    font-size: 17px;
    color: white;
  }
  .episodeName{
    font-size: 13px;
    color: white;
  }
  .imageSecondary{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    align-self: center;
  }
  .boostedItemListGoldInfo{
    display: flex;
    flex:0.8;
    flex-direction: row;
    justify-content: center;
    background-color: #000;
    padding: 10px;
    border-radius: 10px;
    border-width: 1px;
    border-color: black;
    border-style: solid;
  }
  .usernameText{
    font-size: 17px;
    color: #2364E7;
  }
  .sentYouText{
    font-size: 13px;
    color: #757479;
  }
  .goldWeightText{
    font-size: 15px;
    color: #1EB548;
    text-align: right;
  }
  .goldRateText{
    font-size: 13px;
    text-align: right;
    color: white;
  }
  .linkPodcast{
    text-decoration: none;
    cursor: pointer;
  }
  .spinner {
    width: 24px;
    height: 24px;
    border: 2px solid;
    border-color: #fff transparent #fff transparent;
    border-radius: 50%;
    animation: spin-anim 1.1s linear infinite;
    margin-left: 45%;
    align-self: center;
  }
  .noBoostedList{
    font-size: 12px;
    color: white;
  }