.loginContainer{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 400px;
    // background-color: gray;
    position: absolute; /*Can also be `fixed`*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 20;
    justify-content: center;

    .loginHeading{
        margin-left: 10px;
        font-size: 23px;
        margin-bottom: 3px;
        color: #ffffff;
    }
    .loginSubHeading{
        margin-left: 10px;
        font-size: 15px;
        margin-bottom: 30px;
        color: #ffffff;
    }

    .loginForm{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;

        .newPassword{
            width: 100%;
        }

        .inputField{        
            margin-bottom: 20px;
            padding: 15px;
            font-size: 20px;
        }
        .errorMsg{
            font-size: 16px;
            color: red;
            text-align: center;
            margin-top: 2px;
          }
    }

    .spinner {
        width: 24px;
        height: 24px;
        border: 2px solid;
        border-color: #ffffff transparent #ffffff transparent;
        border-radius: 50%;
        animation: spin-anim 1.1s linear infinite;
        margin-left: 45%;
    }

    .loginButton{
        padding: 15px;
        font-size: 20px;
        margin-top: 20px;
        background-color: blue;
        color: white;
    }

    @keyframes spin-anim {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}