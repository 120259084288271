.keywordListContainer{
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    flex-wrap: wrap;
}
.keywordList{
    background-color: silver;
    margin-right: 7px;
    padding: 8px;
    border-radius: 8px;
    margin-top: 5px;
}
.noKeywordList{
    font-size: 12px;
    color: gray;
}